import {useState, useEffect} from "react"
import {ReactComponent as Logo} from "../../assets/logos/hipokratlogo4.svg"




export const Loader = ({ hasImportFinished, enableComponent, isMounted=true }) => {

  const classes = isMounted ? "": "fallback-fadeout"

  return(
      <div className={"d-flex flex-column align-items-center "+classes}>
      
        <Logo width="150px" height="150px" className="mb-3 logo-anim"/>

        <h4 className="text-light text-anim title-logo">&nbsp;H&#183;I&#183;P&#183;O&#183;K&#183;R&#183;A&#183;T</h4>
      </div>)
}  