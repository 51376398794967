import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';

import './scss/style.scss';
import axios from 'axios'

import i18next from "i18next";
import {Helmet} from "react-helmet";

import { connect } from 'react-redux'
import { setUser } from "./redux/actions"
import toast from 'react-hot-toast';

import 'react-bootstrap-typeahead/css/Typeahead.css';
import { withTranslation, Trans } from "react-i18next"
import {Loader} from "./components/utils/Loaders"

import store from './redux/store'

export const LoaderSimple = ({ hasImportFinished, enableComponent }) => {

  return(
    <div className="myContainer d-flex justify-content-center align-items-center">
    </div>)
} 






// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'));
const Register = React.lazy(() => import('./views/pages/register/Register'));
const PasswordReset = React.lazy(() => import ('./views/pages/password/PasswordReset'))
const PasswordNew = React.lazy(() => import ('./views/pages/password/PasswordNew'))

// Axios header settings
axios.defaults.baseURL = 'https://hipokrat.herokuapp.com'; //;
//axios.defaults.baseURL = 'http://0.0.0.0:5000'
//axios.defaults.baseURL = 'http://localhost:8000'
axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"

axios.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token');
    config.headers.authorization = `Bearer ${token}`;
    // config.timeout = 10000 
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

var refreshIntervalId = null;


function activateSilentRefresh(){

    if(refreshIntervalId){
      clearInterval(refreshIntervalId);
    }

    console.log("Activating silent refresh")

    refreshIntervalId = setInterval(function(){

      let refreshToken = localStorage.getItem("token_refresh");
      console.log("Refreshing !")

      axios.post('api/customers/token/refresh/', { refresh: refreshToken })
        .then(res => localStorage.setItem("token", res.data.access)) 
        .catch(err => clearInterval(refreshIntervalId));
    
    }, 30000);
}


//response interceptor to refresh token on receiving token expired error
axios.interceptors.response.use(
  config => config,
  (error) => {

    const originalRequest = error.config;
    let refreshToken = localStorage.getItem("token_refresh");


    if(error.code === "ECONNABORTED"){

      toast.error(<Trans>server_timeout</Trans>)
    
    }
    else if(!window.navigator.onLine){
      toast.error(<Trans>offline</Trans>)

    }else if(error.response){

        if(error.response.status === 401 && originalRequest.url === "api/customers/token/refresh/"){

          
          clearInterval(refreshIntervalId);
          store.dispatch(setUser(null))

        }else if(refreshToken && error.response.status === 401 && !originalRequest._retry){
              
              originalRequest._retry = true
              return axios
                .post('api/customers/token/refresh/', { refresh: refreshToken })
                .then(res => {
                  if (res.status === 200) {
                    localStorage.setItem("token", res.data.access);
                   
                    activateSilentRefresh()

                    return axios(originalRequest);
                  }
                })
        }else if(error.response.status === 401){

          clearInterval(refreshIntervalId);
          store.dispatch(setUser(null))
        
        }else if(error.response.status === 404){
          toast.error(<Trans>404_error</Trans>)
          
        
        }else if(error.response.status === 403){
          toast.error(<Trans>403_error</Trans>)  
        
        }

    }else{
      toast.error(<Trans>app.error.server_unavailable</Trans>)
    }

    return Promise.reject(error);
  }
);



class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loggedIn: false,
      user: null,
    }
    
  }

  componentDidMount(){

      this.getData();
      
  }


  getData = () => {
    axios
      .get("/api/customers/profile/1/")
      .then(res => {
        if (res) {
          this.loginMeIn(res.data)
        }
      })
       .catch( error =>  console.log("Logging required") );
  }

  loginMeIn = (user) => {
    
    if(user){
      i18next.changeLanguage(user.language);
      
      console.log("Login")

      // Setup token automatic renewal
      let refreshToken = localStorage.getItem("token_refresh");

      if(refreshToken){
        activateSilentRefresh();
      }
      
      this.props.setUser(user)
      this.setState({user: user, loggedIn: true}, () => window.location.href = '#')

    }

  }


  logout(){
    this.setState({loggedIn:false})
  }




  render() {
   
      return (
        
        <HashRouter>
          <Helmet>
            <meta name="description" content="Hipokrat - AllInOne"/>
            <meta name="author" content="Francois Heremans"/>
            <meta name="keyword" content=""/>

            <title>Hipokrat</title>
          </Helmet>

            <React.Suspense fallback={<LoaderSimple/>}>
              <Switch>
               {/* <Route exact path="/login" name="Login Page" render={props => <Login onLogin={this.getData} {...props}/>} />*/}
                <Route exact path="/register" name="Register Page" render={props => <Register {...props}/>} />
                <Route path="/password_reset" name="Password reset page" render={props => <PasswordReset {...props} />} />
                <Route path="/password_new" name="Password new page" render={props => <PasswordNew {...props} />} />
                <Route path="/" name="Home" render={props => <TheLayout {...props} logMeIn={()=>this.getData()} />} />
              </Switch>
            </React.Suspense>

        </HashRouter>
        
      );
   
  }
}

export default connect(null, { setUser })(withTranslation()(App))
