import { SET_USER, SET_THEME } from "../constants";


const initialState = {
  user: null,
  theme: null,
};

export default function userReducer(state = initialState, action){

  switch (action.type) {

    case SET_USER: {
      const user = action.payload.user;

      if(!user){
        localStorage.removeItem("token")
        localStorage.removeItem("token_refresh")
      }

      return {
        ...state,
        user: user,
      };
    }

    case SET_THEME: {
      const theme = action.payload.theme;

      return {
        ...state,
        theme: theme,
      };
    }

    default:
      return state;
  }


};