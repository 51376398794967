export const logo = ['50 60', `
  <title>Hipokrat</title>
  <g
     inkscape:label="Layer 1"
     inkscape:groupmode="layer"
     id="layer1"
     transform="translate(-93.375786,-99.125198)">
    <text
       xml:space="preserve"
       style="font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:37.44560242px;line-height:125%;font-family:sans-serif;-inkscape-font-specification:sans-serif;letter-spacing:0px;word-spacing:0px;fill:#109e4a;fill-opacity:1;stroke:none;stroke-width:0.26458332px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1"
       x="91.329399"
       y="141.72725"
       id="text882"><tspan
         sodipodi:role="line"
         id="tspan880"
         x="91.329399"
         y="141.72725"
         style="font-style:normal;font-variant:normal;font-weight:600;font-stretch:normal;font-family:Montserrat;-inkscape-font-specification:'Montserrat Semi-Bold';fill:#1e1e75;fill-opacity:1;stroke-width:0.26458332px"><tspan
   style="font-style:normal;font-variant:normal;font-weight:bold;font-stretch:normal;font-size:63.5px;line-height:127.99999714%;font-family:'Abyssinica SIL';-inkscape-font-specification:'Abyssinica SIL Bold';fill:#1e1e75"
   id="tspan825">H</tspan></tspan></text>
  </g>
`]
