export const sygnet = ['54 54', `
  <title>enaturo sygnet</title>
  <g
     inkscape:label="Layer 1"
     inkscape:groupmode="layer"
     id="layer1"
     transform="translate(-30.534433,-108.85031)">
    <text
       xml:space="preserve"
       style="font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:37.44560242px;line-height:125%;font-family:sans-serif;-inkscape-font-specification:sans-serif;letter-spacing:0px;word-spacing:0px;fill:#109e4a;fill-opacity:1;stroke:none;stroke-width:0.26458332px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1"
       x="28.488047"
       y="151.45236"
       id="text882"><tspan
         sodipodi:role="line"
         id="tspan880"
         x="28.488047"
         y="151.45236"
         style="font-style:normal;font-variant:normal;font-weight:600;font-stretch:normal;font-family:Montserrat;-inkscape-font-specification:'Montserrat Semi-Bold';fill:#000080;fill-opacity:1;stroke-width:0.26458332px"><tspan
           style="font-style:normal;font-variant:normal;font-weight:bold;font-stretch:normal;font-size:63.5px;line-height:127.99999714%;font-family:'Abyssinica SIL';-inkscape-font-specification:'Abyssinica SIL Bold';fill:#000080;stroke-width:0.26458332px"
           id="tspan825">H</tspan></tspan></text>
  </g>
`]
