import 'react-app-polyfill/ie11'; // For IE 11 support
import 'react-app-polyfill/stable';
import './polyfill'
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './i18nextConf';

import { icons } from './assets/icons'

import { Provider } from 'react-redux'
import store from './redux/store'


import { Toaster } from 'react-hot-toast';



React.icons = icons

ReactDOM.render(
  <Provider store={store}>
  	<div>
    	<App/>
	     <Toaster />
     </div>
  </Provider>, 
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
