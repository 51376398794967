import { SET_USER, SET_THEME } from './constants'

export const setUser = user => ({
  type: SET_USER,
  payload: {
    user
  }
});

export const setTheme = theme => ({
  type: SET_THEME,
  payload: {
    theme
  }
});